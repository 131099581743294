@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap");

@import "~slick-carousel/slick/slick.css"; 
@import "~slick-carousel/slick/slick-theme.css";

* {
  font-family: "Poppins", sans-serif;
  scroll-behavior: smooth;
}

body{
  overflow-x: hidden;
}

.slick-slide {
  box-shadow: none;
  border-color:none;
  color: none;
  background: none;
  outline:none;
}


